export default {
    lang: 'por',
    subscription: {
        order_total: 'Valor total da encomenda',
        total: 'Total',
        commodity_information: 'Informação sobre mercadorias',
        product_name: 'Nome do produto',
        type: 'Tipo/Período',
        method: 'Método de pagamento',
        checkout: 'Checkout',
        payment_currency: 'Moeda de pagamento',
        order_number: 'Número da encomenda',
        order_information: 'Informação de Ordem',
        creation_time: 'Tempo de criação',
        phone_number: 'Número de telefone',
        area_code: 'Código de área',
        first_name: 'Nome',
        last_name: 'Apelido',
        email: 'Caixa de correio',
    },
    prompt: {
        email_err: 'Erro no formato do E-mail',
        Mistake: 'Erro',
        close_order: 'Ordem fechada',
        tips:"Conselhos",
    },
    orders: {
        cancel: 'Cancelar',
        cancel_message: 'Se você já fizer o pagamento, cancelar a ordem pode resultar em falha de pagamento. Você tem certeza de que quer cancelar a ordem?',
        Attention: "Atenção",
        sure: 'Confirmar',
        has_cancel:"A ordem foi cancelada !",
    },


}