export default {
    lang:'zh',
    header: {
        official_website: '官网',
        user_enter: '个人中心',
        sign_out: '登出',
        language: '简体中文',
    },
    aside: {
        dash_board: '仪表盘',
        knowledge_base: '使用文档',
        my_order: '我的订单',
        purchase_subscription: '购买订阅',
        node_status: '节点状态',
        subscribe:'订阅',
        finance:'财务',
        user:'用户',
        order_details:'订单详情'
    },
    orders:{
        order_number:'# 订单号',
        cycle:'周期',
        order_amount:'订单金额',
        order_status:'订单状态',
        to_be_paid:'待支付',
        cancelled:'用户取消',
        timeOut:"超时自动取消",
        fail:'支付失败',
        completed:'支付成功',
        creation_time:'创建时间',
        operate:'操作',
        check_the_details:'查看详情',
        cancel:'取消',
        cancel_message:'如果您已经付款，取消订单可能会导致支付失败,确定要取消订单吗?',
        Attention:'注意',
        sure:'确定',
        refunded:'已退款',
        processing:'处理中',
        chargeback:'拒付',
        unknown:'未知',
        cancel_payment:'取消支付'
    },
    personal:{
        change_password:'修改密码',
        old_password:'旧密码',
        please_enter_old_password:'请输入旧密码',
        new_password:'新密码',
        please_enter_a_new_password:'请输入新密码',
        save:'保存',
        reset_subscription_information:'重置订阅信息',
        reset_message:'当你的订阅地址或账户发生泄漏被他人滥用时，可以在此重置订阅信息。避免带来不必要的损失。',
        reset:'重置',
        change_subscription_info:'如果您的订阅地址或信息发生泄露可以执行此操作。重置后您的 UUID 及订阅将会变更，需要重新导入订阅。',
        sure_subscription:'确定要重置订阅信息?'
    },
    document:{
        title:'客户端下载（使用教程）',
        // v2ray
        v2rayNG:{
            name:'📱 安卓客户端 -- v2rayNG',
            step1:'① 请先购买订阅',
            step1_content1:'购买订阅',
            step2:'② 复制节点url地址',
            step2_content1:'购买完成后，请点击一键订阅，并且复制订阅地址',
            step2_content2:'复制订阅地址',
            step3:'③ 下载并安装安卓客户端 -- v2rayNG',
            step3_content1:'点击下载',
            step4:'④ 进入节点订阅设置',
            step4_content1:'- 打开V2rayNG，点击左上角展开更多选项',
            step4_content2:'- 点击“订阅设置”',
            step5:'⑤ 配置订阅',
            step5_content1:'- 进入订阅设置后，点击右上角 “+” 进入节点设置页面',
            step5_content2:'- remarks：填写自定义名称',
            step5_content3:'- Optional URL：输入步骤②粘贴的节点url地址',
            step5_content4:'点击右上角“√”，完成配置',
            step6:'⑥ 更新订阅',
            step6_content1:'- 返回主页面，点击右上角 “:” ',
            step6_content2:'- 选择“更新订阅”',
            step6_content3:'- 节点信息会展示在主页面中',
            step7:'⑦ 开始使用',
            step7_content1:'- 任意选择一个节点',
            step7_content2:'- 点击主页右下角灰色标志 ”V”，待灰色标志变为绿色，即成功连接打开上网。',
        },
        Clash_for_Windows:{
            name:'💻 Windows 客户端 -- Clash for Windows',
            step1:'① 请先购买订阅',
            step1_content1:'购买订阅',
            step2:'② 复制节点url地址',
            step2_content1:'购买完成后，请点击一键订阅，并且复制订阅地址',
            step2_content2:'复制订阅地址',
            step3:'③ 下载并安装Windows 客户端 -- Clash for Windows',
            step3_content1:'点击下载',
            step4:'④ 配置 Clash for Windows',
            step4_content1:'- 打开 Clash for Windows,从侧边栏进入Profiles，输入步骤②复制的URL，然后点击Download按钮',
            step4_content2:'- 进入Proxies,上方选择 Rule,然后在下方的 策略组 中选择你中意的节点。',
            step4_content3:'- 随后从侧边栏回到 General,勾选下方的 System Proxy (设为系统代理)即可。',
        },
        Shadowrocket:{
            name:'🍎 IOS 客户端 - Shadowrocket',
            step1:'① 请先购买订阅',
            step1_content1:'购买订阅',
            step2:'② 复制节点url地址',
            step2_content1:'购买完成后，请点击一键订阅，并且复制订阅地址',
            step2_content2:'复制订阅地址',
            step3:'③ 下载并安装 iOS客户端 -- Shadowrocket',
            step3_content1:'点击下载',
            step4:'④ 配置订阅',
            step4_content1:'- 打开 Shadowrocket 首页，点击右上角的 "+"，再次点击第一行的「Type」，选择 Subscribe/订阅链接。',
            step4_content2:'- 在「Remarks」中输入 MuteNet ，随后在「URL」中粘贴下方您的订阅链接并保存。',
            step4_content3:'- 随后点击右上角保存，此时会自动更新获取服务器。',
            step4_content4:'- 回到首页，点击进入「Global Routing」将其更改为 配置。',
        },
        FoXray:{
            name:'🍎 IOS 客户端 - FoXray',
            step1:'① 请先购买订阅',
            step1_content1:'购买订阅',
            step2:'② 复制节点url地址',
            step2_content1:'购买完成后，请点击一键订阅，并且复制订阅地址',
            step2_content2:'复制订阅地址',
            step3:'③ 下载并安装 iOS客户端 -- FoXray',
            step3_content1:'点击下载',
            step4:'④ 配置订阅',
            step4_content1:'- 打开 FoXray 首页，点击底部的 "Subscriptions"',
            step4_content2:'- 在「Subscriptions」页面，点击右上角的 "+"',
            step4_content3:'- name：输入自定义名称',
            step4_content4:'- URL：输入步骤②复制的URL订阅地址',
            step4_content5:'点击底部 “添加” 即可完成配置',
        },
        Official:{
            name:'📱 官方Android客户端',
            step1:'① 请先购买订阅',
            step1_content1:'购买订阅',
            step2:'② 下载并安装官方Android客户端',
            step2_content1:'点击下载',
            step3:'③ 官方客户端的使用',
            step3_content1:'下载并完成安装意见客户端后，打开，您将会看到客户端的登录界面。输入您的MuteNet账号与密码，就可以直接登录到客户端。登录完成后，点击 连接 即可开始使用我们的服务。',
        }
    },
    pannel:{
        used:'已用',
        total:'总计',
        announcement:'公告',
        my_subscription:'我的订阅',
        expires_on:'到期时间：',
        expiration:'距离到期时间的天数',
        shortcut:'捷径',
        view_tutorial:'查看教程',
        learn:'学习如何使用MuteNet',
        quick_subscription:'一键订阅',
        quick_import:'快速将节点导入对应的客户端进行使用',
        renew_subscription:'续费订阅',
        renew_your_current_subscription:'对您当前订阅进行续费',
        encounter_problems:'遇到问题',
        communicate:"遇到问题可以通过 service{'@'}mutenet.world 邮箱与我们沟通",
        copy:'复制订阅地址',
        qr:'扫描二维码订阅',
        usage :'不会使用，查看使用教程',
    },
    subscription: {
        choose: '请选择最适合您的套餐',
        all: '全部',
        by_cycle :'按周期',
        by_traffic:'按流量',
        order: '下单',
        payment_cycle: '付款周期',
        monthly_payment: '月付',
        quarterly_payment: '季付',
        annual_fee: '年付',
        order_total: '订单总额',
        total: '总计',
        place_an_order: '下单',
        commodity_information:'商品信息',
        product_name:'产品名称',
        type:'类型/周期',
        method:'支付方式',
        checkout:'结账',
        configure_subscription:'配置订阅',
        payment_currency:'支付币种',
        order_number:'订单号',
        order_information:'订单信息',
        creation_time:'创建时间',
        phone_number:'手机号',
        area_code:'地区区号',
        first_name:'名字',
        last_name:'姓氏',
        email: '邮箱',
        order_payment_failed:'订单支付失败',
        order_canceled:'订单已取消',
        hans_sucess:'订单已支付完成并开通',
        view_tutorial:'查看使用教程',
        order_timeout:'订单超时',
        order_expired:'订单过期',
        cancel_payment:'取消支付',
        order_status_unknown:'订单状态未知',
        order_chargeback:'订单拒付',
        order_in_process:'订单处理中',
        order_has_been_refunded:'订单已退款',
        other_subscripe:"该套餐预计预计生效日期为:",
    },
    nodes:{
        name:'名称',
        state:'状态',
        magnification:'倍率',
        label:'标签',
        state_title:'五分钟内节点在线情况',
        magnification_title:'使用的流量将乘以倍率进行扣除'
    },
    login: {
        tip: '更安全、更可靠的去探索世界',
        email: '邮箱',
        password: '密码',
        verification_code: '验证码',
        login: '登入',
        register:'注册',
        forget:'忘记密码',
    },
    register: {
        username:'用户名',
        email_code:'邮箱验证码',
        send:'发送',
        agree:'我已阅读并同意用户许可协议',
        register:"注册",
        return_login:'返回登录',
    },
    prompt:{
        Mistake:'错误',
        Success:'成功',
        picture_code:'请先填入图片验证码',
        username_error:'用户名错误',
        email_err:'邮箱格式错误',
        password_err:'密码不能为空',
        email_code_err:'邮箱验证码不能为空',
        Registration_success:'注册成功',
        Sent_successfully:'发送成功',
        all_not_empty:'用户邮箱、密码、图片验证码均不能为空',
        password_diff:'两次新密码输入不同',
        password_change_success:'密码修改成功',
        reset_successful:'重置成功',
        reset_password:'重置密码',
        enter_discount_code:'输入优惠码',
        verify:'验证',
        invalid_discount_code:'优惠码无效',
        cancel_success:'取消成功',
        paying:'正在支付',
        cancel_order:'在该产品下您还有未完成的订单，购买前需要先取消，确定要取消之前的订单吗？',
        confirm_order_cancellation:'确认取消订单',
        close_order:'Close order',
        no_order:'暂无订单信息',
        no_currencies:'暂无支付货币信息',
        has_subscripe:'您已经有一个可用的套餐，新购买的套餐将在可用套餐过期后生效。是否确认购买？',
        pass_rule:"密码必须包含大小写字母和数字的组合，可以使用特殊字符",
        pass_rule_err:'密码格式错误',
    },
    docx:{
        title:'用户许可协议',
        tip_date:'最近更新日期：2023年8月1日',
        tip1:'引言',
        tip1_content1:'欢迎使用Mute Net（以下简称"本产品"）！本用户许可协议（以下简称"协议"）旨在规定您（以下简称"用户"或"您"）与GENTRY ELECTRONIC CO., LIMITED（以下简称"公司"）之间的关系，并明确了使用本产品的条件。除非您接受本协议所有条款， 否则您无权注册、登录或使用本协议所涉服务。 您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。 本协议约定 Mute Net 与用户之间服务」（以下简称「服务」） 的权利义务。 「用户」是指注册、登录、使用本服务的个人，Mute Net 有权更新协议内容，更新后的协议条款一旦公布即替代原协议条款，恕不再另行通知。',
        tip2:'账号注册',
        tip2_content1:'1.用户在使用本服务前需要注册一个且只能注册一个 「Mute Net」 账号。 「Mute Net」 账号应当使用邮箱绑定注册，请用户使用尚未与其他 「Mute Net」 账号绑定以及未被 Mute Net 根据本协议封禁的邮箱注册 「Mute Net」 账号。 Mute Net 有权根据用户需求或产品需要对账号注册和绑定的方式进行变更，恕不再另行通知。',
        tip2_content2:'2.如果注册申请者有被 Mute Net 封禁的先例、涉嫌虚假注册及滥用他人名义、注册一个以上的账号、或使用可抛弃临时邮箱地址注册，及其他不能得到许可的理由，Mute Net 有权拒绝向其提供服务。',
        tip2_content3:'3.Mute Net有注意到，一些特定的国家（如，中华人民共和国，伊朗伊斯兰共和国，古巴共和国，朝鲜民主主义共和国，等）的公民注册使用Mute Net会被视作违反法律的行为。 Mute Net不建议这类国家的公民注册或是使用我们的服务，也不建议任何其他国家的公民在旅居这类国家的时候注册或是购买Mute Net的服务。 具体请参考公司声明第2条。',
        tip3:'账户安全',
        tip3_content1:'1.用户一旦注册成功，成为 Mute Net 的用户，将得到一个用户名和密码， 并有权利使用自己的用户名及密码随时登录 Mute Net。',
        tip3_content2:"2.用户对账户和密码的安全负全部责任，同时对以其账户进行的所有活动和事件负全部责任。",
        tip3_content3:"3.Mute Net 未经用户允许不得泄露用户的个人私隐与信息。",
        tip3_content3_1:"1） 如果用户密码泄露或者其他任何原因导致用户的密码安全受到威胁，应该及时和 Mute Net 客服人员取得联系，否则将自己承担相应的损失。",
        tip4:'用户声明与保证',
        tip4_content1:'1.用户需具有完全民事行为能力，且具有达成交易履行其义务的能力。',
        tip4_content2:"2.用户订阅的任何由 Mute Net 提供的服务仅适用于用户本人，用户不被允许在任何平台以任何形式共享自己的账号、链接、订阅地址以及其他导致多人共享服务的信息。",
        tip4_content3:"3.用户通过使用 Mute Net 的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用账号或本服务所产生的内容，不得违反用户所在地区/国家或是巴西联邦共和国的法律相关法律制度， 包含但不限于如下原则：反对宪法所确定的基本原则的",
        tip4_content3_a:"a.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的",
        tip4_content3_b:"b.损害国家荣誉和利益的",
        tip4_content3_c:"c.煽动民族仇恨、民族歧视，破坏民族团结的",
        tip4_content3_d:"d.破坏国家宗教政策，宣扬邪教和封建迷信的",
        tip4_content3_e:"e.散布谣言，扰乱社会秩序，破坏社会稳定的",
        tip4_content3_f:"f.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的",
        tip4_content3_g:"g.侮辱或者诽谤他人，侵害他人合法权益的",
        tip4_content3_h:"h.含有法律、行政法规禁止的其他内容的",
        tip4_content4:'4.用户不得利用 「Mute Net」 账号或本服务制作、上载、复制、发布、传播下干扰正常运营，以及侵犯其他用户或第三方合法权益的内容：含有任何性或性暗示的',
        tip4_content4_a:"a.含有辱骂、恐吓、威胁内容的",
        tip4_content4_b:"b.含有骚扰、垃圾广告、恶意信息、诱骗信息的",
        tip4_content4_c:"c.涉及他人私隐、个人信息或资料的",
        tip4_content4_d:"d.侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的",
        tip4_content4_e:"e.含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息",
        tip5:"服务内容",
        tip5_content1:"1.Mute Net具体服务内容由 Mute Net 根据实际情况提供，",
        tip5_content2:"2.给用户的通告及其他消息都可通过邮箱或者用户所提供的联系方式发送。",
        tip6:"隐私政策",
        tip6_content1:"您同意公司按照其隐私政策收集、使用和处理您的个人信息。请阅读公司的使用条款中的隐私政策以了解详细信息。",
        tip7_content1:"更新和支持",
        tip7_content2:"公司可能会不定期提供本产品的更新和升级，以提供新功能、改进性能和修复漏洞。您同意自动接收这些更新，并且公司不对更新引发的问题承担责任",
        tip8:"免责声明",
        tip8_content1:"1. 本产品按现状提供，不提供任何明示或暗示的担保，包括但不限于适销性、特定用途适用性和非侵权性的担保。",
        tip8_content2:"2. 公司不对由于使用本产品而导致的任何直接或间接损失承担责任，包括但不限于数据损失、利润损失、业务中断等。",
        tip9:"终止",
        tip9_content1:"公司保留随时终止您的使用许可，如果您违反了本协议的任何条款。在终止后，您将停止使用本产品，并删除与本产品相关的所有内容和数据。",
        tip10:"适用法律和争议解决",
        tip10_content1:"1. 本协议受香港特别行政区的法律管辖。任何争议应首先通过双方友好协商解决。",
        tip10_content2:"2. 如果协商不成，争议将提交到香港特别行政区法院，并按照其仲裁规则解决。",
        tip11:"协议变更",
        tip11_content1:"公司保留随时修改本协议的权利。更新后的协议将在即日生效。您继续使用本产品即表示接受修改后的协议。",
    },
    state:{
        no_state:'No available nodes, if you are not subscribed or have expired,please',
        order:' subscribe'
    }

}