export default {
    lang: 'en',
    orders: {
        cancel: 'Cancel',
        cancel_message: 'If you have already made the payment, canceling the order may result in payment failure. Are you sure you want to cancel the order?',
        Attention: 'Attention',
        sure: 'Determine',
        has_cancel:"The order has been cancelled !",
    },
    subscription: {
        order_total: 'Order total',
        total: 'Total',
        place_an_order: 'Place an order',
        commodity_information: 'Commodity Information',
        product_name: 'Product Name',
        type: 'Type/Period',
        method: 'Payment method',
        checkout: 'Checkout',
        payment_currency: 'payment currency',
        order_number: 'order number',
        order_information: 'Ordering Information',
        creation_time: 'creation time',
        phone_number:'Phone number',
        area_code:'Area code',
        first_name:'First name',
        last_name:'Last name',
        email: 'E-mail',
        
    },
    prompt: {
        email_err: 'Email format error',
        Mistake: 'Mistake',
        close_order:'Close order',
        tips:"Tips"
    },
}