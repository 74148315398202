import { createI18n } from 'vue-i18n'
import ZH from './zh.js';
import EN from './en.js';
import POR from './por'
const messages = {
  zh: { ...ZH },
  en: { ...EN },
  por: { ...POR }
};

let language = sessionStorage.getItem('lang')

if (language === null || language === undefined) {
  language = 'por'
  sessionStorage.setItem('lang',language)
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: language,
  messages
});
export default i18n;