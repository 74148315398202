<template>
  <router-view/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  border:0
}
#app, body, html {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #495057;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-variant: tabular-nums;
    line-height: 1.5;
    background-color: #F0F3F8;
    font-feature-settings: "tnum";
    min-width: 338px;
}
</style>
